<template>
  <div class="form_item">
    <input
      v-if="type === 'color'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="color"
    />
    <input
      v-else-if="type === 'date'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="date"
    />
    <input
      v-else-if="type === 'datetime-local'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="datetime-local"
    />
    <input
      v-else-if="type === 'email'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="email"
    />
    <input
      v-else-if="type === 'file'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="file"
    />
    <input
      v-else-if="type === 'hidden'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="hidden"
    />
    <input
      v-else-if="type === 'month'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="month"
    />
    <input
      v-else-if="type === 'number'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="number"
    />
    <input
      v-else-if="type === 'password'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="password"
    />
    <input
      v-else-if="type === 'range'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="range"
    />
    <input
      v-else-if="type === 'tel'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="tel"
    />
    <input
      v-else-if="type === 'time'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="time"
    />
    <input
      v-else-if="type === 'url'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="url"
    />
    <input
      v-else-if="type === 'week'"
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      type="week"
    />
    <input
      v-else
      :name="name"
      :id="`form_field_${name}`"
      :required="required"
      :placeholder="placeholder"
      :class="`form_input ${extraClass}`"
      :value="value"
      :disabled="isDisabled"
      v-on:input="updateValue($event.target.value)"
      v-on:focus="$emit('focus')"
      v-on:blur="$emit('blur', $event.target.value)"
      type="text"
    />
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    extraClass: {
      default: '',
      required: false,
      type: String
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    name: {
      default: 'text',
      required: true,
      type: String
    },
    placeholder: {
      default: '',
      required: false,
      type: String
    },
    required: {
      default: false,
      required: false,
      type: Boolean
    },
    type: {
      default: 'text',
      required: false,
      type: String
    },
    value: {
      default: '',
      required: false,
      type: String
    }
  },

  methods: {
    updateValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>
