<template>
  <div class="inline">
    <div v-if="type === 'submit-form'">
      <button
        :class="`button button--primary ${extraClass}`"
        type="submit"
        :disabled="isDisabled">
        <span v-if="text">
          {{ text }}
        </span>
        <font-awesome-icon
          v-if="iconProp && iconProp.length"
          :icon="iconProp"
          :size="iconSize" />
      </button>
    </div>
    <div v-else-if="type === 'login'">
      <button
        @click="$parent.login()"
        :class="`button button--primary ${extraClass}`"
        :disabled="isDisabled">
        <span v-if="text">
          {{ text }}
        </span>
        <font-awesome-icon
          v-if="iconProp && iconProp.length"
          :icon="iconProp"
          :size="iconSize"
          class="test3" />
      </button>
    </div>
    <div v-else-if="type === 'register'">
      <button
        @click="$parent.register()"
        :class="`button button--primary ${extraClass}`"
        :disabled="isDisabled">
        <span v-if="text">
          {{ text }}
        </span>
        <font-awesome-icon
          v-if="iconProp && iconProp.length"
          :icon="iconProp"
          :size="iconSize"
          class="test2" />
      </button>
    </div>
    <div v-else-if="type === 'submit'">
      <button
        @click="$parent.submit()"
        :class="`button button--primary ${extraClass}`"
        :disabled="isDisabled">
        <span v-if="text">
          {{ text }}
        </span>
        <font-awesome-icon
          v-if="iconProp && iconProp.length"
          :icon="iconProp"
          :size="iconSize"
          class="test" />
      </button>
    </div>
    <div v-else>
      <div v-if="path">
        <nuxt-link
          :to="localePath(path)"
          :class="`button button--primary ${extraClass}`">
          <span v-if="text">
            {{ text }}
          </span>
          <font-awesome-icon
            v-if="iconProp && iconProp.length"
            :icon="iconProp"
            :size="iconSize" />
        </nuxt-link>
      </div>
      <div v-else>
        <a
          :href="link"
          :class="`button button--primary ${extraClass}`"
          :target="target">
          <span v-if="text">
            {{ text }}
          </span>
          <font-awesome-icon
            v-if="iconProp && iconProp.length"
            :icon="iconProp"
            :size="iconSize" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonPrimary',
  props: {
    type: {
      default: 'link',
      required: false,
      type: String
    },
    target: {
      default: '_blank',
      required: false,
      type: String
    },
    path: {
      required: false,
      type: [String, Object]
    },
    link: {
      required: false,
      type: [String, Object]
    },
    text: {
      default: '',
      required: false,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    },
    iconProp: {
      default: () => [],
      required: false,
      type: Array
    },
    iconSize: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
